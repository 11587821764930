import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  IconChevronDown,
  IconFolder,
} from '@tabler/icons-react';
import AddJenisBarang from '../components/Admin/AddJenisBarang';
import ConfirmationLogOut from '../components/ConfirmationLogOut';
import { IconLogout } from '@tabler/icons-react';

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  // const navigation = useNavigation()
  const [openSubMenuBarang, setOpenSubMenuBarang] = useState(false);
  const [openSubMenuTransaksi, setOpenSubMenuTransaksi] = useState(false);
  const [openSubMenuCashflow, setOpenSubMenuCashflow] = useState(false);
  const [openSubMenuHistori, setOpenSubMenuHistori] = useState(false);
  //   const [openSideBar, setOpenSidebar] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);



  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  // <span onClick={openModal}>Jenis Barang</span>
  // const check_user_role = localStorage.getItem('role');

  const navigate = useNavigate();

  function ConfirmationLogOutModal() { }

  function logOut() {
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('tipe_surat');
    navigate('/login');
  }
  const user = localStorage.getItem('role');
  // const role = localStorage.getItem('role');

  return (
    <div>
      <AddJenisBarang isOpen={isOpen} closeModal={closeModal} />
      <ConfirmationLogOut />
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 md:hidden md:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none '
          }`}
        aria-hidden="true"
      ></div>
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col w-[16rem] absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen  lg:sidebar-expanded:!w-72 2xl:!w-72 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between pr-3 sm:px-2">
          {/* Close button */}
          <div className="ml-4">
            <h1 className="text-lg uppercase text-center cursor-pointer font-bold text-slate-100 border-b border-gray-100  w-full">
              Komaladewi
            </h1>
          </div>
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
        </div>

        {/* Links */}
        <div className="space-y-8">
          <div className="flex flex-col justify-start item-center">
            <div className=" my-4  border-gray-100 pb-4">
              <div
                onClick={() => navigate('/')}
                className="flex mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto">
                <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                <NavLink
                  to="/"
                  className=" text-gray-200 group-hover:text-white font-semibold "
                >
                  Dashboard
                </NavLink>
              </div>
              <div
                onClick={() => setOpenSubMenuBarang(!openSubMenuBarang)}
                className={`
                  flex  mb-2 justify-between hover:text-white items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <div className="flex  items-center gap-4 text-white">
                  <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                  Barang
                </div>
                <div className="text-gray-200">
                  <IconChevronDown className="w-5 h-5" />
                </div>
              </div>
              {/* sub menu */}
              <div className={`pl-[50px] ${openSubMenuBarang ? '' : 'hidden'}`}>
                <div className="">
                  <div
                    onClick={openModal}
                    className={`py-2.5 text-sm  cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 `}
                  >
                    Jenis Barang
                  </div>
                  <div
                    onClick={() => navigate('/list-items-admin')}
                    className={`py-2.5 text-sm  cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  `}
                  >
                    <NavLink to={'/list-items-admin'}>List Barang</NavLink>
                  </div>

                  {/* <div
                  onClick={() => navigate('/barang-opname')}
                    className={`py-2.5 text-sm cursor-pointer  hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap`}
                  >
                    <NavLink to={'/barang-opname'}>Barang Opname</NavLink>
                  </div> */}
                  {/* <div
                    onClick={() => navigate('/barang-rusak')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/barang-rusak'}>Barang Rusak</NavLink>
                  </div> */}
                  {/* <div
                    onClick={() => navigate('/transfer-barang')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/transfer-barang'}>Transfer Barang</NavLink>
                  </div>
                   */}
                  <div
                    onClick={() => navigate('/barang-terhapus')}
                    className={`py-2.5 text-sm  cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  `}
                  >
                    <NavLink to={'/barang-terhapus'}>Barang dikeluarkan</NavLink>
                  </div>
                  {/* <div
                    onClick={() => navigate('/laporan-karat-item')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/laporan-karat-item'}>Laporan</NavLink>
                  </div> */}
                </div>
              </div>
              {/* end sub menu */}
              <div
                onClick={() => setOpenSubMenuTransaksi(!openSubMenuTransaksi)}
                className={` flex  mb-2 justify-between hover:text-white items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <div className="flex items-center gap-4 text-white">
                  <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                  Transaksi
                </div>
                <div className="text-gray-200">
                  <IconChevronDown className="w-5 h-5" />
                </div>
              </div>
              {/* submenu transaksi */}
              <div
                className={`pl-[50px] ${openSubMenuTransaksi ? '' : 'hidden'}`}
              >
                <div className="">
                  <div
                    onClick={() => navigate('/transaksi-penjualan')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                    <NavLink to={'/transaksi-penjualan'}>Penjualan</NavLink>
                  </div>

                  <div
                    onClick={() => navigate('/transaksi-pembelian')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                    <NavLink to={'/transaksi-pembelian'}>Pembelian</NavLink>
                  </div>
                  <div
                    onClick={() => navigate('/list-transaksi-tertunda')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/list-transaksi-tertunda'}>Penggarus</NavLink>
                  </div>
                  <div
                    onClick={() => navigate('/summary')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/summary'}>Laporan</NavLink>
                  </div>
                </div>
              </div>
              {/* end submenu transaksi */}
              {/* <label
                htmlFor="user_link"
                className={` flex  mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                <NavLink
                  to={'/list-users'}
                  id="user_link"
                  className=" text-gray-200 group-hover:text-white font-semibold  "
                >
                  Users
                </NavLink>
              </label> */}

              {/* CASHFLOW */}
              <div
                onClick={() => setOpenSubMenuCashflow(!openSubMenuCashflow)}
                className={` flex  mb-2 justify-between hover:text-white items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <div className="flex items-center gap-4 text-white">
                  <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                  Cashflow
                </div>
                <div className="text-gray-200">
                  <IconChevronDown className="w-5 h-5" />
                </div>
              </div>
              {/* submenu transaksi */}
              <div
                className={`pl-[50px] ${openSubMenuCashflow ? '' : 'hidden'}`}
              >
                <div className="">
                  <div
                    onClick={() => navigate('/saldo-kas')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                    <NavLink to={'/saldo-kas'}>Saldo KAS</NavLink>
                  </div>
                  <div
                    onClick={() => navigate('/pengeluaran')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                    <NavLink to={'/pengeluaran'}>Transaksi Luar</NavLink>
                  </div>
                  <div
                    onClick={() => navigate('/laporan-cashflow')}
                    className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2  whitespace-nowrap">
                    <NavLink to={'/laporan-cashflow'}>Laporan</NavLink>
                  </div>
                </div>
              </div>
              {user === 'admin' ? (
                <div onClick={() => navigate('/riwayat')}>
                  <label
                    htmlFor="riwayat"
                    className={` flex  mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
                  >
                    <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                    <NavLink
                      to={'/riwayat'}
                      id="riwayat"
                      className=" text-gray-200 group-hover:text-white font-semibold  "
                    >
                      Riwayat
                    </NavLink>
                  </label>
                </div>
              ) : (
                <>
                  <div
                    onClick={() => setOpenSubMenuHistori(!openSubMenuHistori)}
                    className={` flex  mb-2 justify-between hover:text-white items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
                  >
                    <div className="flex items-center gap-4 text-white">
                      <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                      Riwayat
                    </div>
                    <div className="text-gray-200">
                      <IconChevronDown className="w-5 h-5" />
                    </div>
                  </div>
                  <div
                    className={`pl-[50px] ${openSubMenuHistori ? '' : 'hidden'}`}
                  >
                    <div className="">
                      <div
                        onClick={() => navigate('/histori-transfer-masuk')}
                        className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                        <NavLink to={'/histori-transfer-masuk'}>Riwayat Barang Masuk</NavLink>
                      </div>
                      <div
                        onClick={() => navigate('/histori-transfer-keluar')}
                        className="py-2.5 text-sm cursor-pointer hover:bg-green-900 text-gray-200 hover:text-white px-2 ">
                        <NavLink to={'/histori-transfer-keluar'}>Riwayat Barang Keluar</NavLink>
                      </div>
                    </div>
                  </div>
                </>
              )}


              {/* END CASHFLOW */}

              {/* <div
                className={`flex mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <BiMessageSquareDots className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                <NavLink to={'/saldo-kas'}>
                  <h3 className=" text-gray-200 group-hover:text-white font-semibold ">Saldo KAS</h3>
                </NavLink>
              </div> */}
              {/* <div
                className={`flex mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto`}
              >
                <MdOutlineIntegrationInstructions className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                <NavLink to={'/pengeluaran'}>
                  <h3 className=" text-gray-200 group-hover:text-white font-semibold ">Pengeluaran</h3>

                </NavLink>
              </div> */}
              {user !== 'admin' && (
                <div onClick={() => navigate('/tambah-barang')} className="flex  mb-2 justify-start items-center gap-4 pl-5 hover:bg-green-900 p-2  group cursor-pointer hover:shadow-lg m-auto">
                  <IconFolder className="text-2xl text-gray-200 group-hover:text-white w-5 h-5" />
                  <NavLink to={'/tambah-barang'} className={`text-white`}>
                    Tambah Barang
                  </NavLink>
                </div>
              )}
            </div>
            <div className='absolute bottom-3 left-8 w-[80%] '>
              <button onClick={logOut} className='hover:bg-slate-700 bg-slate-800 hover:text-red-500 text-white px-4 py-1.5  w-full flex items-center gap-3 ' >
                <IconLogout className='w-5 h-5' />
                Logout
              </button>
            </div>
            {/* setting  */}
            {/* <div className="flex  w-full justify-center border  h-40">
              <div className="text-center my-auto">
                <h2 className="text-white font-semibold text-lg">{user}</h2>
                <div
                  onClick={logOut}
                  className=" cursor-pointer  text-red-500 hover:text-red-600 hover:font-bold font-semibold"
                >
                  Log Out
                </div>
              </div>
            </div> */}

            {/* logout */}

          </div>
        </div>

        {/* Expand / collapse button */}
        {/* <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
