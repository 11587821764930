import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DinamicTitle } from '../utils/DinamicTitle';
import { http } from '../config/http';
import TypeWriter from 'typewriter-effect';

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPasswor] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  function handleOnChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const { username, password } = formData;

  async function handleSubmitLogin(e) {
    e.preventDefault();
    const dataLogin = {
      username,
      password,
    };
    setIsLoading(true);
    try {
      const response = await http.post(`auth/login`, dataLogin);
      navigate('/');
      toast.success('Login Berhasil!!', {
        autoClose: 1000,
      });
      localStorage.setItem('token', response?.data?.access_token);
      localStorage.setItem('name', response?.data?.user?.name);
      localStorage.setItem('role', response?.data?.user?.role[0]);
      localStorage.setItem('tipe_surat', parseInt(response?.data?.user?.cabang?.tipe_surat));
      localStorage.setItem('id_brankas', response?.data?.brankas[0]?.id);
      // console.log(response.data)
    } catch (error) {
      toast.error(error.response?.data?.error);
    }
    setIsLoading(false);
  }
  DinamicTitle('Login || Komaladewi');

  const tahunCopy = new Date().getFullYear();
  const tahunDibuat = '2023';

  return (
    <>
      <div className="bg-no-repeat bg-cover bg-center relative" style={{ backgroundImage: 'url(bg.jpg)' }}>
        <div className="md:absolute bg-gradient-to-b from-green-500 to-slate-400/50 opacity-75 inset-0" />
        <div className="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
          <div className="flex-col flex  self-center p-10 sm:max-w-5xl xl:max-w-2xl  z-10">
            <div className="self-start hidden lg:flex flex-col  text-white">
              <h1 className="mb-3 font-bold text-5xl flex">
                Hi? &nbsp;
                <span>
                  <TypeWriter
                    options={{
                      autoStart: true,
                      loop: true,
                      delay: 40,
                      strings: ['Selamat Datang'],
                    }}
                  />
                </span>
              </h1>
              <p className="pr-3 font-semibold text-2xl text-slate-100">
                Aplikasi Transaksi Penjualan dan Pembelian Emas dan Perhiasan{' '}
                <span className="font-bold border-b text-orange-400 border-orange-400">Bersurat</span> di Toko Komala
                Dewi
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmitLogin} className=" justify-center self-center mx-2 md:mr-20 lg:mr-2 z-10">
            <div className="p-8 bg-white mx-auto  w-[400px] ">
              <div className="mb-4 ">
                <div>
                  <h3 className="font-semibold text-2xl text-gray-800">Sign In </h3>
                  <p className="text-gray-500 text-sm">Silahkan Masuk Ke Akun Anda</p>
                </div>
              </div>
              <div className="space-y-5">
                <div className="space-y-2">
                  <label className="text-sm font-medium text-gray-700 tracking-wide">Username</label>
                  <input
                    type="text"
                    name="username"
                    autoComplete="off"
                    onChange={handleOnChange}
                    required
                    placeholder="Masukkan Username "
                    className="input input-bordered rounded-none bg-white input-success w-full max-w-xs"
                  />
                </div>
                <div className="space-y-2">
                  <label className="mb-5 text-sm font-medium text-gray-700 tracking-wide">Password</label>
                  <input
                    type={`${showPassword ? `text` : `password`}`}
                    name="password"
                    onChange={handleOnChange}
                    required
                    autoComplete="off"
                    placeholder="Masukkan Password"
                    className="input input-bordered rounded-none bg-white input-success w-full max-w-xs"
                  />
                </div>
                <div className="flex items-center mb-4">
                  <input
                    id="passwordShow"
                    type="checkbox"
                    onClick={() => setShowPasswor(!showPassword)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="passwordShow"
                    className="ml-2 text-sm font-normal text-gray-900 dark:text-gray-300 cursor-pointer"
                  >
                    Lihat Password
                  </label>
                </div>
                <div className="flex items-center justify-between"></div>
                <div>
                  <button
                    type="submit"
                    className="w-full  flex justify-center bg-green-400 hover:bg-green-500 text-gray-100 p-3  tracking-wide font-semibold  shadow-lg cursor-pointer transition ease-in duration-500"
                  >
                    {isLoading ? (
                      <>
                        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
                        Processing...
                      </>
                    ) : (
                      'Sign In'
                    )}
                  </button>
                </div>
              </div>
              <div className="pt-5 text-center text-gray-400 text-xs">
                <span>
                  Copyright © {tahunDibuat} - {tahunCopy === tahunDibuat ? tahunCopy : ''} &nbsp;
                  <Link to="#" target="_blank" title="KomalaDewi" className="text-green hover:text-green-500 ">
                    Komala Dewi
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
