import { Dialog, Transition } from '@headlessui/react';
import { useState } from 'react';
import { Fragment } from 'react';
import QRCode from 'react-qr-code';
import { imageBaseUrl } from '../../config/http';
import { IconCopy, IconCheck } from '@tabler/icons-react';
import { PhotoView } from 'react-photo-view';
import ImageComponent from '../../utils/ImageComponent';
import { IconX } from '@tabler/icons-react';

export default function DetailItem({ closeModalDetail, openDetail, detailItem }) {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  function openLah() {
    closeModalDetail();
    setOpenInput(false);
  }
  const [openInput, setOpenInput] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openImage2, setOpenImage2] =  useState(false);
  const [openImage3, setOpenImage3] =  useState(false);
  const [load, setLoad] = useState(false);
  const [copy, setCopy] = useState(false);

  const handleCopy = (kodeItem) => {
    navigator.clipboard.writeText(kodeItem);
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };

  return (
    <>
      <Transition appear show={openDetail} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          {/* {openImage && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700/20  flex flex-col items-center justify-center">
              <div
                onClick={() => setOpenImage(false)}
                className={'absolute top-52 right-7 lg:top-16 lg:right-[280px] md:right-[120px] md:top-28 '}
              >
                <div className="bg-white p-2 rounded-full cursor-pointer hover:bg-white/50">
                  <IconX />
                </div>
              </div>
              <div>
                {!load && (
                  <div className="max-w-[48px] max-h-[48px]  rounded-full animate-spin border-4 border-dashed border-green-500 border-t-transparent"></div>
                )}
                <img
                  onLoad={() => setLoad(true)}
                  width={''}
                  className={'lg:w-[700px] md:w-[500px] w-[350px] scale-50'}
                  height={''}
                  src={detailItem?.gambar === null ? '' : `${imageBaseUrl}${detailItem?.gambar[0]?.asli}`}
                />
              </div>
            </div>
          )} */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center p-4 min-h-[440px]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden bg-white px-6 pt-5 pb-3 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    <div className="flex items-center justify-between">
                      <div className="block">
                        <div className="flex gap-3 items-center font-semibold text-2xl">
                          {/* <IconCategory className="text-slate-500" /> */}
                          {detailItem?.jenis_kategori?.nama_jenis}
                        </div>{' '}
                        <div className="flex gap-1">
                          KODE BARANG: {detailItem?.kode_item}
                          {copy ? (
                            <button className="-mt-2">
                              <IconCheck className="w-5 h-5 stroke-1" />
                            </button>
                          ) : (
                            <button onClick={() => handleCopy(detailItem?.kode_item)} className="-mt-2">
                              <IconCopy className="w-5 h-5 stroke-1" />
                            </button>
                          )}
                        </div>
                        <div className="text-slate-500 text-sm">{detailItem?.created_at}</div>
                      </div>
                      <div className="text-center mb-2">
                        <QRCode
                          value={detailItem?.kode_item === undefined ? 'unkwow' : detailItem?.kode_item}
                          size={80}
                        />
                      </div>
                    </div>
                    <div className="border rounded">
                      <div className="p-1 border-b bg-orange-500/10 text-slate-600">
                        <h2>Detail Barang</h2>
                      </div>

                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Berat
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">{detailItem?.berat} Gram</div>
                        </div>
                      </div>

                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Emas
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">{detailItem?.karat} Karat</div>
                        </div>
                      </div>

                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Harga Modal / gram
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            Rp {numberWithCommas(detailItem?.harga_modal === undefined ? '0' : detailItem?.harga_modal)}
                          </div>
                        </div>
                      </div>

                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Lokasi toko saat ini
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">{detailItem?.cabang?.nama_cabang}</div>
                        </div>
                      </div>

                      <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Status
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            {detailItem?.is_ready === true ? <>Tersedia</> : <> Tidak Tersedia</>}
                          </div>
                        </div>
                      </div>
                      {!detailItem?.history_transfer?.length ? '' : <>
                        <div className="border-b my-1 text-base font-normal text-slate-600 flex justify-between px-5">
                          Riwayat Lokasi Barang
                        </div>

                        <div className="relative overflow-x-auto">
                          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                              <tr>
                                <th scope="col" className="px-6 py-2">
                                  No
                                </th>
                                <th scope="col" className="px-6 py-2">
                                  Asal
                                </th>
                                <th scope="col" className="px-6 py-2">
                                  Tujuan
                                </th>
                                <th scope="col" className="px-6 py-2">
                                  Tgl Transfer
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {detailItem?.history_transfer?.map((ht, i) => (
                                <tr
                                  key={i}
                                  className="hover:bg-gray-100 bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                >
                                  <th
                                    scope="col"
                                    className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                  >
                                    {i + 1}.
                                  </th>
                                  <td className="px-6 py-2">{ht.asal_cabang.nama_cabang}</td>
                                  <td className="px-6 py-2">{ht.tujuan_cabang.nama_cabang}</td>
                                  <td className="px-6 py-2">{ht.created_at}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div></>
                      }
                      {/* <div className="border-b mt-1 text-base font-normal text-slate-600 flex justify-between px-5">
                        Histori Barang
                        <div className="text-gray-900">
                          <div className="rounded-sm text-green-500 pb-2">
                            {detailItem?.history_transfer?.map((ht, i) => (
                              <div key={i}>
                                <div className="flex items-center justify-between">
                                  <div>Asal Cabang :</div>
                                  <div>{ht.asal_cabang.nama_cabang}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div> */}

                      <div className="py-2 text-base font-normal text-slate-600 flex justify-between px-5">
                        Gambar :
                      </div>
                      <div className="bg-gray-200 mx-5 p-2 flex gap-3 rounded mb-2">
                        <div className="border border-green-500 overflow-hidden">
                          {openDetail && (
                            <PhotoView
                              src={detailItem?.gambar1 === null ? '' : `${imageBaseUrl}${detailItem?.gambar1[0]?.asli}`}
                            >

                              <img width={'80px'}
                                height={'80px'} src={detailItem?.gambar1 === null ? '' : `${imageBaseUrl}${detailItem?.gambar1[0]?.asli}`} alt="" />
                            </PhotoView>
                          )}

                          {/* {openDetail && (
                            <ImageComponent
                              onClick={() => setOpenImage(true)}
                              className={'max-w-[80px]  hover:scale-110 cursor-pointer'}
                              width={'80px'}
                              height={'80px'}
                              style={{ objectFit: 'cover' }}
                              src={detailItem?.gambar1 === null ? '' : `${imageBaseUrl}${detailItem?.gambar1[0]?.asli}`}
                            />
                          )} */}
                        </div>
                        {/* <div className="border border-green-500">
                          {openDetail && (
                            <ImageComponent
                              onClick={() => setOpenImage2(true)}
                              className={'hover:scale-110 cursor-pointer'}
                              width={'80px'}
                              height={''}
                              src={detailItem.gambar2 === null ? '' : `${imageBaseUrl}${detailItem.gambar1[0].asli}`}
                            />
                          )}
                        </div>
                        <div className="border border-green-500">
                          {openDetail && (
                            <ImageComponent
                            onClick={() => setOpenImage3(true)}
                            className={'hover:scale-110 cursor-pointer'}
                              width={'80px'}
                              height={''}
                              src={detailItem.gambar3 === null ? '' : `${imageBaseUrl}${detailItem.gambar1[0].asli}`}
                            />
                          )}
                        </div> */}
                      </div>
                    </div>
                    <div className="mt-3 flex items-center justify-end gap-3">
                      <button
                        onClick={openLah}
                        className="focus:outline-none py-2 px-5 shadow-sm text-center text-gray-100 bg-slate-600 hover:bg-slate-700 font-medium border"
                      >
                        Tutup
                      </button>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
